import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const POLLING_INTERVAL = 12000;

export const injected = new InjectedConnector({
    supportedChainIds: [56],
});

export const RPC_URLS = {
    56: "https://bsc-dataseed.binance.org/"
};

export const walletconnect = new WalletConnectConnector({
    rpc: RPC_URLS,
    qrcode: true,
});