import React from 'react'
import logo from '../img/logo.png';
import ConnectButton from './helper/ConnectButton';

export default function Header() {
  return (
    <header className="main-header">
        <nav className="navbar navbar-expand-sm">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="logo" />
                </a>
                <ConnectButton/>
            </div>
        </nav>
    </header>
  )
}
