import React, { useState } from 'react';
import { useWeb3React } from "@web3-react/core";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap-button-loader';
import { parseUnits } from '@ethersproject/units';
import { getContract } from './contractData';
import * as item from '../../hooks/constant';



export default function BuyButton(props) {

    const context = useWeb3React();
    const { library, account } = context;
    const { amount } = props;
    const [loading, setLoading] = useState(false);

    const handleBuytoken = async () => {
        try {
            setLoading(true);
            if (account) {
                const presale_contract = await getContract(library);

                if (isNaN(amount)) {
                    toast.error('please enter valid amount!');
                    setLoading(false);
                    return;
                }
                else if (parseFloat(amount) === 0) {
                    toast.error('please enter valid amount!');
                    setLoading(false);
                    return;
                }
                else if (parseFloat(amount) < parseFloat(item.minInvest) || parseFloat(amount) > parseFloat(item.maxInvest)) {
                    toast.error(`amount must be between ${item.minInvest} BNB and ${item.maxInvest} BNB !`);
                    setLoading(false);
                    return;
                }
                else {
                    let user_balance = await library.getBalance(account);
                    user_balance = user_balance.toString();
                    if(parseFloat(user_balance) > parseFloat(amount)){
                        let tx = await presale_contract.investBNB(account, { 'from': account, 'value': parseUnits(amount).toString() });
                        let response = await tx.wait();
                        if (response) {
                            if (response.status === 1) {
                                toast.success('success ! Your Last Transaction is Successfull.');
                                setLoading(false);
                            }
                            else if (response.status === 0) {
                                toast.error('error ! Your Last Transaction is Failed.');
                                setLoading(false);
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                            }
                        }
                        else {
                            toast.error('Opps ! Your adddress is not whitelisted!');
                            setLoading(false);
                        }
                    }
                    else{
                        toast.error('Opps !Insufficient funds for transfer!');
                        setLoading(false);
                    }
                }
            }
            else {
                toast.error(`Please Connect Wallet !`);
                setLoading(false);
            }
        }
        catch (err) {
            typeof err.data !== 'undefined' ? toast.error(err.data.message) : toast.error(err.message)
            // toast.error(typeof err.data.message !== 'undefined' ? err.data.message : err.message  );
            setLoading(false);
        }
    }


    return (
        <>
            
            <div className="text-center my-3">
                <Button loading={loading} className='btn btn-primary' onClick={handleBuytoken}>Buy Now</Button>
            </div>
        </>
    )
}
