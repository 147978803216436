import Header from './components/Header';
import Main from './components/Main';
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { POLLING_INTERVAL } from "./hooks/connectors";
import {  ToastContainer } from 'react-toastify';

export function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
}

function App() {
  return (
    <>
    <ToastContainer />
      <Web3ReactProvider getLibrary={getLibrary}>
        <Header />
        <Main />
      </Web3ReactProvider>
    </>
  );
}

export default App;
