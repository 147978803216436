import React, { useState } from 'react'
import bnb_img from '../img/bnb.png';
import logo from '../img/logo.png';
import * as item from '../hooks/constant';
import BuyButton from './helper/BuyButton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useWeb3React } from "@web3-react/core";

const token_link = `https://bscscan.com/address/${item.tokenAddress}`;
const presale_link = `https://bscscan.com/address/${item.presale_address}`;

export default function Main() {
    const context = useWeb3React();
    const { account } = context;
    const [amount, setAmount] = useState(0);
    const [token, setToken] = useState(0);
    const [error_msg, setError_msg] = useState('');



    const handleAmountChange = (e) => {
        setAmount(e.target.value);
        if (isNaN(e.target.value)) {
            setError_msg('(please enter valid amount.)');
            setToken(0);
            return;
        }
        else if (parseFloat(e.target.value) === 0) {
            setError_msg('Please enter valid amount');
            setToken(0);
            return;
        }
        else if (parseFloat(e.target.value) < item.minInvest || parseFloat(e.target.value) > item.maxInvest) {
            setError_msg(`amount must be between ${item.minInvest} BNB and ${item.maxInvest} BNB`);
            setToken(0);
            return;
        }
        else {
            setError_msg('');
            setToken(parseFloat((e.target.value * item.tokenPerBNB).toFixed(3))); 

        }

    }


    const handleTokenAdd = async () => {
        try {
            if (account) {
                // wasAdded is a boolean. Like any RPC method, an error may be thrown.
                await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20', // Initially only supports ERC20, but eventually more!
                        options: {
                            address: item.tokenAddress, // The address that the token is at.
                            symbol: item.symbol, // A ticker symbol or shorthand, up to 5 chars.
                            decimals: item.decimal, // The number of decimals in the token
                            image : "https://www.oosale.app/favicon11.png"

                        },
                    },
                });
            }
            else {
                toast.error('Please connect wallet !');
            }
        } catch (error) {
            toast.error(error.message);
        }
    }



    return (
        <>
           
            <section className="banner-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-12 col-lg-9">
                            <div className="text-center">
                                <p className="headings">Presale for {item.symbol} Begins..</p>
                                <p className="headings">Early access to {item.symbol} tokens</p>
                            </div>
                            <div className="card mt-4">
                                <div className="card-body">
                                    <p>1 BNB = {item.tokenPerBNB} {item.symbol}</p>
                                    <p className="mgtp">Pay with</p>
                                    <div className="input-group">
                                        <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={(e) => handleAmountChange(e)} value={amount} />
                                        
                                        <span className="input-group-text" id="basic-addon2"><img src={bnb_img} alt="bnb-img" className="img-icon mx-2" />BNB</span>
                                    </div>
                                    <small className='mt-0 text-danger mb-3'>{error_msg}</small>
                                    <p className="mgtp mt-3">You will get</p>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={token} />
                                        
                                        <span className="input-group-text" id="basic-addon2"><img src={logo} alt="logo-img" className="img-icon mx-2" />{item.symbol}</span>

                                    </div>


                                    <BuyButton amount={amount.toString()} />

                                    <div className="smart"><i className="fa fa-lock me-2" aria-hidden="true"></i>
                                        <p>100% Secure smart contract</p>
                                    </div>
                                </div>
                            </div>
                            <div className="link">
                                <a href={token_link} target="_blank" rel="noreferrer" >{item.symbol} Token contract</a>
                            </div>
                            <div className="link">
                                <a href={presale_link} target="_blank" rel="noreferrer">{item.symbol} presale contract</a>
                            </div>
                            <div className="link">
                                <button className='link-button' onClick={handleTokenAdd}>Add {item.symbol} token to your Metamask On Click</button>
                            </div>
                            <div className="footer">
                                <div className="footer-images">
                                    {/* <div className="footer-block">
                                        <i className="fab fa-2x fa-youtube" aria-hidden="true"></i>
                                        <div className="footer-text">
                                            <h4>
                                                <a href={item.youtube_link} target="_blank" rel="noreferrer">YOUTUBE</a>
                                            </h4>
                                        </div>
                                    </div> */}
                                    <div className="footer-block">
                                        <i className="fab fa-2x fa-twitter" aria-hidden="true"></i>
                                        <div className="footer-text">
                                            <h4>
                                                <a href={item.twitter_link} target="_blank" rel="noreferrer">TWITTER</a>
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="footer-block">
                                        <i className="fab fa-2x fa-telegram" aria-hidden="true"></i>
                                        <div className="footer-text">
                                            <h4>
                                                <a href={item.telegram_link} target="_blank" rel="noreferrer">TELEGRAM</a>
                                            </h4>
                                        </div>
                                    </div>
                                    {/* <div className="footer-block">
                                        <i className="fab fa-2x fa-discord"></i>
                                        <div className="footer-text">
                                            <h4>
                                                <a href={item.instagram_link} target="_blank" rel="noreferrer">INSTAGRAM</a>
                                            </h4>
                                        </div>
                                    </div> */}
                                </div>
                                <p className="rights">All Rights reserved. oosale.app</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
